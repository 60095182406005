main {
  padding-top: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
}
header h1 {
  text-align: center;
  color: white;
  background-color: #00b8d4;
}
.social-media-icons {
  
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Add margin spacing from the content above */
  margin-bottom: 20px;
}

.social-media-icons a {
  margin: 0 10px; /* Add spacing between the icons */
}

.social-media-icons svg {
  width: 24px; /* Set the width of the icons */
  height: 24px; /* Set the height of the icons */
  fill: #fff; /* Set the color of the icons */
  transition: fill 0.2s ease-in-out; /* Add transition effect for hover */
}

.social-media-icons svg:hover {
  fill: #00b8d4; /* Change the color of the icons on hover */

}





@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
body {

  

  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: small;
  /* background-image: url(./Wave3.png);
 background-size:cover ;
 background-repeat: no-repeat; */
  background-image: radial-gradient(circle at 0% 50%, #373b52, #252736 51%, #1d1e26);
  color: #fff;
  overflow-y: scroll; /* Enable vertical scrolling */
  scrollbar-width: none; /* Hide the default scrollbar on supported browsers */
  -ms-overflow-style: none; /* Hide the default scrollbar on IE/Edge */
}
/* For Webkit browsers (e.g. Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.5em; /* Width of the scrollbar */
  background-color: #ffffff; /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #959595; /* Color of the scrollbar thumb */
}

/* For Mozilla Firefox */
::-moz-scrollbar {
  width: 0.5em;
  background-color: #ffffff;
}

::-moz-scrollbar-thumb {
  background-color: #ffffff;
}

/* body {
  background-image: radial-gradient(
    circle at 0% 50%,
    #373b52,
    #252736 51%,
    #1d1e26
  );
} */
.footer #button {
  width: 35px;
  height: 35px;
  /* border-right: #00b8d4 8px solid; */
  border-left: #00b8d4 8px solid;
  border-top: #00b8d4 8px solid;
  border-radius: 5px;
  transform: rotate(45deg);
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.footer #button:hover {
  /* width: 35px; */
  /* height: 35px; */

  /* -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  position: relative; */
}
.footer {
  bottom: 0px;
  left: 0;
  position: fixed;
  width: 100%;
  height: 2rem;
  margin: 0 auto;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  z-index: 999;
}
.footer:hover {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
  height: 14em;
}
.footer #container {
  margin-top: 5px;
  width: 100%;
  height: 150%;
  position: relative;
  top: 0;
  left: 0;
  background: #ffffff;
  color: #1d1e26;
}
.footer #cont {
  position: relative;
  top: -45px;
  right: 190px;
  width: 150px;
  height: 2px;
  margin: 0 auto;
}
.footer_center {
  width: 500px;
  float: left;
  text-align: center;
}
.footer h3 {
  font-family: "Helvetica";
  margin-top: 70px;
  margin-left: 40px;
}

.LandingPage{
  min-height: 70vh;
}
.inputBox {
  display: flex;
flex-direction: column;
align-content: space-between;
/* margin: 20px; */
  margin-bottom: 20px;
  /* width: 200px; */
  padding: 5px;
margin-left: 100px;
  max-width: 300px;
  color: black;
  /* align-items: center; */
  border-radius: 0 10px 5px;
  background: rgb(255, 255, 255);
  background: rgb(251, 231, 209);
  margin-top: 0%;

}

.inputTextArea {
  height: 300px;
  width: 600px;
}
.inputReduce {
  min-height: 400px;
}
.rewriteForm {
  min-width: 800px;
  min-height: 100px;
  /* margin-top: 0%; */
}

.landingPageNavBar {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  color: rgb(255, 255, 255);
  /* font-size: large; */
  /* background-image:radial-gradient(circle at 0% 50%, #ffffff, #ffffff 51%, #ffffff); */
  max-height: 50px;
  box-shadow: 0px 2px 5px  rgb(0, 194, 248) ;


}
.landingPageNavBar ul {
  margin-top: -10px;
}
.landingPageNavBar li {
  margin-top: 0px;
  opacity: 0;
	animation: fadeIn 1s ease-in both;

}


.landingPageNavBar li:nth-child(2) {
	animation-delay: 1s;
}
.landingPageNavBar li:nth-child(3) {
	animation-delay: 1.5s;
}
.landingPageNavBar li:nth-child(4) {
	animation-delay: 1.7s;
}
.landingPageNavBar li:nth-child(5) {
	animation-delay: 1.8s;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.navBar li {
  display: flex;
  display: inline-block;
  margin: 1em;
  /* border-bottom: 2px solid white; */
  /* border-radius: 2px; */

  transition: border 0.1s ease-in-out;
}
.navBar li:hover {
  border-bottom: 2px solid #00b8d4;
}
.navBar .active {
  border-bottom: 2px solid #00b8d4;
  color: #00b8d4;
}

.landingPageNavBar li:hover{
  border: none;
}

.navBar {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 800;
  text-transform: uppercase;
}
.userProfile {
  display: inline-block;
  padding: 10px;

  align-items: center;
}

.user-details{
text-align:center;
  font-size: larger;
  height: 70vh;
}


.userPicture {
margin: auto;
  width: 100px;
  border-radius: 20%;
  margin-bottom: 10px;
}
.userName {
  color: #1d1e26;
}
@media only screen and (max-width: 800px) {
  .landingPageNavBar ul {
    margin-top: 0px;
  }
  .user-details{
    text-align: start;
    height: 60vh;
    font-size: small;
    margin-top: 20px;
  }
  .userPicture{
    width: 60px;
  }
  }
.banner {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  
  
  background-image:radial-gradient(circle at 0% 50%, #ffffff, #ffffff 51%, #ffffff);

  max-height: 40px;
  box-shadow: 0px 2px 5px  rgb(0, 194, 248) ;

}
.logOut {
  position: absolute;
  right: 0%;
  top: 0%;
  margin-left: 0%;
  background: #1d1e26;
  color: white;
  padding: 10px;
  width: 150px;
  height: 40px;
  border-bottom-left-radius: 100%;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}

.logOut:hover {
  background: #252736;
}

.logOut svg {
  margin-right: 8px;
  fill: white; /* Set the fill color of the SVG icon to white */
  width: 20px;
  height: 20px;
  transition: 0.3s;
}

.logOut:hover svg {
  fill: #ffd700; /* Change fill color of the SVG icon on hover */
}

.howItWorks {
  background: #1d1e26;
 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #f4f4f4; */
  color: black;
  font-size:larger;
}
.howItWorks .responseBox {
  max-width: 1300px;
}

.wavy-border {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  /* margin-top: -100px; */
 
}

.wavy-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wavy-border svg {
  width: 100%;
  height: 100%;
}

.howItWorks .welcome{
  color: white;
}
.pricing{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;


}
.howItWorks h2 {
  font-weight: bold;
}
.white-line {

  max-width: 1000px;
  margin: 0 auto;
  border-top: 1px solid white;

  box-shadow: 0px 5px 10px #b3e4eb  ;
}
.content {
  max-width: 800px;

  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0px 10px 50px;
  box-shadow: 0px 5px  #b3e4eb  ;
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1.5s ease-out, transform 1.5s ease-out;
}
.howItWorks .copy{
  max-width: 900px;
  font-size:larger;
  color: white;
  font-weight: bold;
  padding: 20px;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}



#signIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: auto;
  /* background: red; */
}

.introHeader {

  margin: auto;
  margin-top: 3rem;
  max-width: 550px;
  font-size: x-large;
  font-weight:bolder;
  letter-spacing: 0px;
  padding: 20px;
  margin-bottom: -40px;


}
.mainHeader {

 
  font-size: 80px;
  letter-spacing: -1px;
  color: #00b8d4;
}
.landingPageCopy {
  min-height: 170px;
  /* font-weight: bold; */
}

@media only screen and (max-width: 800px) {

  .mainHeader {
    font-size: 50px;
  }
  .landingPageCopy {
    font-size: 25px;
    min-height: 180px;
  }
  .generateButton {
    font-size: smaller;
  }
  .inputBox {
    margin: auto;
    margin-bottom: 10px;
    width: 300px;
    /* background: rgb(251, 231, 209); */
  }
}


.landingPageHero {
  display: flex;
  animation: fade-up 1.5s ease-in-out forwards;


}
.aiBotImage {

  border-radius: 50%;
width: 250px;
height: 250px;
margin-top: 3rem;
align-self: center;
/* margin-left: -100px; */
margin-right: 250px;

padding: 0px;
box-shadow: #00b8d4 -10px 2px 10px;






}
@media only screen and (max-width: 770px) {
  .landingPageHero {
    
    flex-direction: column;
  }
  .aiBotImage {
    margin: auto;
  }
}
.sideBarButton{
  margin-left: 20px;
  color: #00b8d4;
  min-width: 50px;
  transition: 0.5s;
}
.sideBarButton:hover {
  color: #0097b6;
}
.buttonUp{
  transform: rotate(-180deg);
  transition: 0.5s;
}

.sideBar {
  background: #ffffff;
  /* min-height: 95vh; */
  position: absolute;
  top: 2rem;
  width: 150px;
  height: 40px;
  left: 0%;
  /* margin-top: 3.6px; */
  color: #1d1e26;
  transition: 0.5s;
  border-bottom-right-radius: 100%;
  /* display: none; */
}

@media only screen and (max-width: 770px) {
  .sideBar {
    width: 80px;
  }
  .logOut {
    width: 80px;
    padding-left: 5%;
    margin: 0%;
    border-bottom-left-radius: 100%;
  }
}

.open {
  height: 100vh;
  width: 300px;
  transition: 0.5s;
  z-index: 1;
  background:#00677b;
  padding: 10px;
  opacity: 0.98;

}
.sideBar .upgradeBtnSidebar {
  background: orange;
  margin: -10px;
  padding: 10px;
  width: 200px;
  border-radius: 10px;
}
.sideBar .upgradeBtnSidebar:hover {
 background: rgb(11, 175, 216);
 color: white;
}

.ulClose {
  /* Hide the text. */
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;
  display: none;
}

.ulClose a {
  text-indent: -9999px;
  display: none;

}

.sideBarUl {
  display: flex;
  flex-direction: column;
  transition-duration: 0.5s;
  padding: 10px;
  
}

/* Updated styles for ul and li elements */

.sideBar ul {
  list-style-type: none;
  padding: 0;
  margin: 10px;

}

.sideBar li {
  margin-bottom: 10px;
  
}

.sideBar a {
  text-decoration: none;
  color: #1d1e26;
  transition: color 0.3s;
  color: rgb(255, 255, 255);
}

.sideBarHover {
  color: #00b8d4;
 
  border-radius: 10px;
  
  padding: 5px;

}
.sideBarHover:hover {
  background: rgba(128, 128, 128, 0.5); /* Grey color with 50% opacity */
}




.responseBox {
font-size: large;
  /* position: absolute; */
  margin-bottom: 40px;
  background: white;
  padding: 20px;
  margin: 40px;
  border-radius: 0 10px 50px;
  min-height:300px ;
  color: #1d1e26;
  min-width: 800px;
  max-width: 800px;
  box-shadow: 0px 5px  #b3e4eb  ;
  animation: fade-up 1s ease-in-out forwards;

}
.responseArea {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  /* max-width: 600px; */
  /* background: red; */


}
.crudButtonArea {
  display: inline-flex;
  animation: fade-up 1.5s ease-in-out forwards;
}

.saveButton {
  align-content: space-between;
  margin-left: 40px;
  max-width: 800px;
  margin-bottom: 0%;
  fill: #fff; /* Set the color of the icons */
  transition: fill 0.5s ease-in-out; /* Add transition effect for hover */

}
.createNewButton {
  background: #0097b6;
  padding-left:10px ;
  padding-right: 10px;
  border-radius: 5px;
}
.createNewButton:hover {
  background: #01d4fe; /* Change the color of the icons on hover */

}
.bottomButton{
  margin-bottom: 1.5rem;
}
.trash {
  position: absolute;
  left: 100%;
  color: #d43500;
}
.saveButton svg:hover {
  fill: #7fecff; /* Change the color of the icons on hover */

}
.form textarea, .inputBox {
  background: none;
  color: #1d1e26;
  background-color: #f3f3f3;

}
form, .registerBox {
  display: flex;
  flex-direction: column;
  color: #00677b;

}
.registerBox textarea {
  border-radius: 10px;
  padding: 20px;
  color: #0097b6;
}
.loadingData {
  margin: auto;
  font-size: large;
  font-weight: 800px;
}

.form {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  /* min-width: 800px; */
  /* background: red; */
}

.savedAds{
  margin: 40px;
  padding: 10px;
 color: black;
 border-radius: 0 10px 50px;
 box-shadow: 0px 5px  #b3e4eb  ;
}
.savedContent{
  /* margin: 20px; */
  background: white;
  border-radius: 10px;
}

.crudButton {
margin: 10px;
background: #0097b6;
  border-radius: 5px;
  padding: 10px;
}

.edit {
background: #c6f1fa;

}

@media only screen and (max-width: 770px) {
  .form {
    flex-direction: column;

  }
  .responseBox {
min-width: 200px;
/* font-size: larger; */
margin-left: 0px;
margin-right: 0px;
  }
  .inputBox {
    padding: 0px;
  }
  .crudButtonArea .saveButton {
margin: 0px;
margin-right: 20px;
max-width: 200px;
  }
  .crudButtonArea {
    max-width: 200px;
  }

  .navBar {
    font-size: x-small;
  }

}

.generateButton {
  color: white;
  padding: 10px;
  margin-left: 180px;
  padding: 12px 24px;
  margin-top: 10px;
  background-color: #00b8d4;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}
.generateButton:hover {
  background-color: #0097b6;
}
.popup-content {
  background: #fff;
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  color: #1d1e26;
}

.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0;

  transition: opacity 0.5s ease-in, visibility 0.5s ease-out;
}

.popup, .visible {
  opacity: 0;
	animation: fadeIn 0.5s ease-in-out both;
}
.disclosure {
  margin: 40px;
  color: rgb(252, 252, 156);
}

@media only screen and (max-width: 770px) {
  /* .popup {
   position: sticky;
   top: 30%;
   font-size: medium;
   height: 200px;
   width: fit-content;
   margin-left: -4%;
   bottom: -100%;
  } */
 }
 .closeButton {
  cursor: pointer;
  float: right;
  margin-top: -50px;
  margin-right: -30px;
  font-size: x-large;
  color: #0097b6;
}

.loadingQuote {
  font-weight: bolder;
  /* font-size: larger; */
  max-width: 400px;
  color: #0097b6;
  transition: 0.5s;
  font-style: italic;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 50px; */
}

.loading-spinner {
  width: 60px;
  height: 60px;
margin-top: 50px;
margin-bottom: 40px;
}

.imgLogo {
  position: absolute;
  top: 1%;
  left: 1%;
  width: 100px;
  border-radius: 40%;
}
.welcome.checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  font-size: small;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 5px;
}
.pricing {
  background: #0056b3;
  padding: 10px;
  border-radius: 5px;
}

.product-comparison {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.product-comparison h3 {
  margin-bottom: 20px;
  color: #333;
}

.product-comparison h5 {
  margin: 10px 0;
  color: #666;
}

.plan-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plan-option {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 45%;
}

.plan-option h4 {
  margin-bottom: 10px;
  color: #007bff;
}

.plan-option p {
  margin: 5px 0;
  color: #333;
}

.upgrade-button {
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.upgrade-button:hover {
  background-color: #0056b3;
}

.plan-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.product {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
.product h3 {
  margin-bottom: 10px;
  color: #333;
}

.product h5 {
  margin-bottom: 20px;
  color: #666;
}

#chatContainer {
margin-left: 30%;

  background: #38394d;
  width: 500px;
  /* height: 500px; */

  min-width: 300px;
  border-radius: 10px;
}
#chatBox {
display: flex;
flex-direction: column;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  max-width: 20%;
  min-width: 100%;
}



#chatBox.user {
  background-color: #ffffff;
  color: #1d1e26;
  transition: 0.5s;
}

#chatBox.bot {
  background-color: #292b44;
  align-self: flex-start;
  transition: 0.5s;
}
.textArea {

  width: 400px;
  height: 50px;
  text-align: start;
  margin-right: -80px;
 
}
.chatInputArea{
display: flex;
background: white;
color: #1d1e26;
border: none;


}



/* Dashboard */

.dashboard {
  height: 70vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.welcome {
  font-size: 48px;
  font-weight: bold;
  animation: fade-up 1s ease-in-out forwards;
}
.welcome.small {
  font-size: large;
}
@media only screen and (max-width: 770px) {
  .welcome {
    padding: 10px;
    font-size: x-large;
margin-bottom: 20px;

  }
  .container {
    display: flex;
    flex-direction: column;
    
  }
}

@keyframes fade-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  display: flex;
background: white;
  /* flex-direction: row; */
  justify-content: center;

  margin-top: 50px;
  border-radius: 10px;


}

.card {
display: flex;
flex-direction: column;
  background-color: #fff;
  border-radius: 0px 10px 50px;
  box-shadow: 0px 5px  #b3e4eb  ;
  height: 200px;
  padding: 30px;
  margin: 20px;
  /* max-width: 400px; */

  text-align: center;

  animation: fade-up 1.9s ease-in-out forwards;
}
.card.large {
height: 200px;
}

.card h3 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #666;
  
}

.card p {
  font-size: 18px;
  color: #666;
}

.container :hover{
 background-color: #0097b6;
 transition: 0.3s;
}

.contactUs {
  display: flex;
  background: white;
  min-height: 50vh;
}



.google-btn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  transition: 0.5ms;
}
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .google-btn:hover {
    /* box-shadow: 0 0 3px #4285f4; */
    background-color: #4173c5;
    transition: 0.3s;

    
  }
  .google-btn:active {
    background: #1669F2;
  }


@import url(https://fonts.googleapis.com/css?family=Roboto:500);

/* STRIPE CSS */

/* section {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 112px;
  border-radius: 6px;
  justify-content: space-between;
  
}
#checkout-and-portal-button {
  
  background: #242d60;
}
.product {
  display: flex;
  flex: 1;
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
img, svg {
  border-radius: 6px;
  margin: 10px;
  width: 54px;
  height: 57px;
}
h3,
h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  margin: 0;
}
h5 {
  opacity: 0.5;
}
button {
  height: 36px;
  background: #556cd6;
  color: white;
  width: 100%;
  font-size: 14px;
  border: 0;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.6;
  border-radius: 0 0 6px 6px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}
button:hover {
  opacity: 0.8;
} */

.lead-capture-page {
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8;
  color: black;
  border-radius: 5px;
}

.leadh2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.leadp {
  font-size: 16px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.leadlabel {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.leadinput[type='text'],
.leadinput[type='email'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

.leadbutton {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.leadbutton:hover {
  background-color: #357bd8;
}

/* PRICING CARDS */

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
 

}

.pricing-card {
  background-color: #f8f8f8;
  border-radius: 0px 10px 50px;
  padding: 30px;
  text-align: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  min-height: 400px;
  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0px 5px  #b3e4eb  ;
  animation: fade-up 1s ease-in-out forwards;
}

.pricing-card-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: black;
}

.pricing-card-price {
  font-size: 36px;
  margin-bottom: 20px;
  color: black;
}

.pricing-card-price span {
  font-size: 18px;
}

.pricing-card-features {
  list-style-type: none;

  padding: 0;
  margin-bottom: 30px;
  color: black;
}

.pricing-card-features li {
  margin-bottom: 10px;
  color: black;
}

.pricing-card-button {
  background-color: #4c84ff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-card-button:hover {
  background-color: #346ad2;
}


.notPremium {
  display: flex;
  flex-direction: row;
}
.notPremiumH {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  background: red;
  font-size:x-large;
}
.notPremium {
  display: flex;
flex-direction: column;
  align-items: center;
  align-content: center;
  margin-left: 50%;
margin: 20px;
font-size:x-large;

width: 100%;
}

.notPremium p {

}
