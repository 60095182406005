.welcome.checkout {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.product-comparison {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 900px;
    width: 100%;
    margin: 20px 0;
}

.product-comparison h3 {
    text-align: center;
    margin-bottom: 40px;
}

.plan-options {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 20px;
}

.plan-option {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.plan-option:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.plan-option h4.plan-title {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
}

.plan-option h4.plan-price {
    color: #333;
    font-size: 20px;
    margin-top: 20px;
}

.plan-option p {
    color: #666;
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px; /* Space for the tick mark */
}

.plan-option p::before {
    content: "✔"; /* Unicode for the check mark */
    color: green;
    position: absolute;
    left: 0;
    top: 0;
}

.plan-footer {
    margin-top: 20px;
}

/* .createNewButton {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
    margin-top: 20px;
    transition: background-color 0.3s;
} */

/* .createNewButton:hover {
    background-color: #0056b3;
} */

.upgrade-button {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.upgrade-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .plan-options {
        flex-direction: column;
        align-items: center;
    }

    .plan-option {
        margin-bottom: 20px;
        width: 90%;
    }

    .product-comparison {
        padding: 20px;
    }

    .upgrade-button {
        width: 90%;
        margin: 0 auto;
    }
}
