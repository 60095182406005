.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -48px;
    background-color: #f7f7f7;
  }

  @media only screen and (max-width: 440px) {

    .login-container {
        margin-top: 0px;
    }
  }
  
  .login-box {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    padding: 40px;
    text-align: center;
    width: 400px;
    color: #333;
  }
  
  .login-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
  }
  
  .login-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .name-inputs {
    display: flex;
    justify-content: space-between;
  }
  
  .input-container {
    width: 48%;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #6c4eff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }
  
  .separator::before {
    margin-right: .25em;
  }
  
  .separator::after {
    margin-left: .25em;
  }
  
  .separator-text {
    color: #666;
  }
  
  .google-button,
  .microsoft-button {
    width: 100%;
    padding: 12px;
    background-color: white;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .button-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .signup-text {
    margin-top: 20px;
    color: #666;
  }
  
  .signup-link {
    color: #6c4eff;
    text-decoration: none;
    cursor: pointer;
  }
  