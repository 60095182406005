.tips-container{
  
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
}
.tips-container {
    /* max-width: 800px; */
    height: 100vh;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
h1 {
    text-align: center;
    color: #2c3e50;
}
ul {
    list-style-type: none;
    padding: 0;
}
.tip-number {
    /* background-color: #ecf0f1; */
    margin: 10px 0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
li.tip-number:nth-child(even) {
    background-color: #bdc3c7;
}
.tip-number {
    font-weight: bold;
    color: #1a1817;
}